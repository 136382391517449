<template>
  <div class="mt-5">
    <Navbar />    
    <Section title="Know what the term means" small-text="A small discription of the words we use befor you use the calculator." component-name="KeyTerms" />
    <Section title="Price Calculator" small-text="Please fill in the details to get Approximate calculation of your sale on BEX" component-name="Calculator" />    
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from '../components/Navbar.vue'
import Section from '../components/Section.vue'
import Footer from '../components/Footer.vue'

export default {
  name: 'About',
  components: {
    Navbar,    
    Section,
    Footer
  }
}
</script>